import React from "react";

const Footer = () => {
    return ( 
        <div className="h-24">
            
        </div>
     );
}

export default Footer;