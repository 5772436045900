
export const navLinks = [
    {
      id: "more_about_me",
      title: "More About Me",
    },
    {
      id: "experience",
      title: "Experience",
    },
    {
      id: "projects",
      title: "Projects",
    },
    {
      id: "resume",
      title: "Resume",
    },
  ];
  